import ScanController from '@/3d-app/scan/ScanController';
import MeshUtils from '@/3d-app/commons/MeshUtils';
import Tool from '@/3d-app/commons/Tool';
import { Raycaster, Vector2, Vector3 } from 'three';

class AlignSoleTool extends Tool {
    private _originPosition: Vector3 = new Vector3();

    private _originPointer3D: Vector3 = new Vector3();

    private _raycaster: Raycaster;

    private _isInTransform: boolean;

    get isInTransform(): boolean {
        return this._isInTransform;
    }

    public constructor() {
        super();

        this._raycaster = new Raycaster();
        this._isInTransform = false;
    }

    public pointerDownLogic(pointerX: number, pointerY: number): void {
        if (this.isSoleUnderPointer(pointerX, pointerY)) {
            const scanController = ScanController.instance;
            if (!scanController) {
                throw new Error('ScanController not ready!');
            }
            // Save origin 3D pointer position
            this._originPointer3D = MeshUtils.mouseToWorld(
                pointerX,
                pointerY,
                scanController.containerSize,
                scanController.camera,
                scanController.camera.position.clone().multiply(new Vector3(1, 1, 0)),
            );
            // Save position
            this._originPosition = scanController.currentSoleMesh.mesh.position.clone();
            this._isInTransform = true;
        }
    }

    private isSoleUnderPointer(pointerX: number, pointerY: number): boolean {
        const scanController = ScanController.instance;
        if (!scanController) {
            throw new Error('ScanController not ready!');
        }
        this._raycaster.setFromCamera(
            new Vector2(
                (pointerX / scanController.containerSize.width) * 2 - 1,
                -(pointerY / scanController.containerSize.height) * 2 + 1,
            ),
            scanController.camera,
        );

        const hit = this._raycaster.intersectObject(scanController.currentSoleMesh.soleGeometry); // Check only current sole

        return hit.length > 0;
    }

    public pointerUpLogic(): void {
        this._isInTransform = false;
    }

    public pointerMoveLogic(pointerX: number, pointerY: number): void {
        if (!this._isInTransform) {
            return;
        }

        const scanController = ScanController.instance;
        if (!scanController) {
            throw new Error('ScanController not ready!');
        }

        const pointer3DPos = MeshUtils.mouseToWorld(
            pointerX,
            pointerY,
            scanController.containerSize,
            scanController.camera,
            scanController.camera.position.clone().multiply(new Vector3(1, 1, 0)),
        );

        const meshPos = scanController.currentSoleMesh.mesh.position;
        meshPos.copy(this._originPosition.clone().add(pointer3DPos.sub(this._originPointer3D)));
        // Limit the movement in z only
        meshPos.x = this._originPosition.x;
        meshPos.y = this._originPosition.y;
        // Limit z between [10, -50] too, to prevent loosing the sole in the distance
        meshPos.z = Math.min(
            Math.max(meshPos.z, -500 * ScanController.SCALE_TO_M_FACTOR),
            100 * ScanController.SCALE_TO_M_FACTOR,
        );
    }
}

export default AlignSoleTool;
