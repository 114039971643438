import AnimatedCamera from '@/3d-app/commons/camera/AnimatedCamera';
import CustomTrackballControls from '@/3d-app/commons/camera/controls/CustomTrackballControls';
import {
    ControlsOperation,
    TouchGesture,
    type ICameraControls,
} from '@/3d-app/commons/camera/controls/ICameraControls';
import type { TCameraParams } from '@/3d-app/commons/camera/TCameraParams';

class TrackballCamera extends AnimatedCamera {
    protected createControls(rendererDomElement: HTMLCanvasElement): ICameraControls {
        return new CustomTrackballControls(this, rendererDomElement);
    }

    constructor(cameraParams: TCameraParams) {
        super(cameraParams);
        this.initializeControls();
        this.controls.addMouseAction(ControlsOperation.ZOOM, 'WHEEL');
        this.controls.addMouseAction(ControlsOperation.ROTATE, 0);
        this.controls.addMouseAction(ControlsOperation.PAN, 1);
        this.controls.addMouseAction(ControlsOperation.ZOOM, 2);

        this.controls.addTouchAction(ControlsOperation.ROTATE, TouchGesture.ONE_PAN);
        this.controls.addTouchAction(ControlsOperation.ROTATE, TouchGesture.ROTATE);
        this.controls.addTouchAction(ControlsOperation.PAN, TouchGesture.TWO_PAN);
        this.controls.addTouchAction(ControlsOperation.ZOOM, TouchGesture.PINCH);
    }
}

export default TrackballCamera;
