import ScanController from '@/3d-app/scan/ScanController';
import MeshUtils from '@/3d-app/commons/MeshUtils';
import Tool from '@/3d-app/commons/Tool';
import { Quaternion, Raycaster, Vector2, Vector3 } from 'three';

class AlignScanTool extends Tool {
    private _capturedPointerButton: number;

    private _originPosition: Vector3 = new Vector3();

    private _originRotation: Quaternion = new Quaternion();

    private _originPointer3D: Vector3 = new Vector3();

    private _originalAngleOffset: number = 0;

    private _raycaster: Raycaster;

    get isInTransform(): boolean {
        return this._capturedPointerButton !== -1;
    }

    public constructor() {
        super();

        this._capturedPointerButton = -1;
        this._raycaster = new Raycaster();
    }

    private isScanUnderPointer(pointerX: number, pointerY: number): boolean {
        const scanController = ScanController.instance;
        if (!scanController) {
            throw new Error('ScanController not ready!');
        }
        this._raycaster.setFromCamera(
            new Vector2(
                (pointerX / scanController.containerSize.width) * 2 - 1,
                -(pointerY / scanController.containerSize.height) * 2 + 1,
            ),
            scanController.camera,
        );

        const hit = this._raycaster.intersectObject(scanController.currentScanMesh.scanGeometry); // Check only current scan

        return hit.length > 0;
    }

    public pointerDownLogic(pointerButton: number, pointerX: number, pointerY: number): void {
        if ((pointerButton === 0 || pointerButton === 2) && this._capturedPointerButton === -1) {
            if (this.isScanUnderPointer(pointerX, pointerY)) {
                const scanController = ScanController.instance;
                if (!scanController) {
                    throw new Error('ScanController not ready!');
                }
                // Left or right buttons only
                this._capturedPointerButton = pointerButton;
                // Save origin 3D pointer position
                this._originPointer3D = MeshUtils.mouseToWorld(
                    pointerX,
                    pointerY,
                    scanController.containerSize,
                    scanController.camera,
                );
                // Save transformations
                const tmpVec = new Vector3();
                scanController.currentScanMesh.mesh.matrix.decompose(
                    this._originPosition,
                    this._originRotation,
                    tmpVec,
                );
                // Save angle offset
                this._originalAngleOffset = scanController.currentScanMesh.angleOffset;
            }
        }
    }

    public pointerUpLogic(pointerButton: number): void {
        if (pointerButton !== this._capturedPointerButton) {
            return;
        }
        this._capturedPointerButton = -1;
    }

    public pointerMoveLogic(pointerX: number, pointerY: number): void {
        if (this._capturedPointerButton === -1) {
            return;
        }

        const scanController = ScanController.instance;
        if (!scanController) {
            throw new Error('ScanController not ready!');
        }

        const pointer3DPos = MeshUtils.mouseToWorld(
            pointerX,
            pointerY,
            scanController.containerSize,
            scanController.camera,
        );

        if (this._capturedPointerButton === 0) {
            // Left button down
            // Translation movement
            const meshPos = scanController.currentScanMesh.mesh.position;
            const prevPos = meshPos.clone();
            meshPos.copy(this._originPosition.clone().add(pointer3DPos.sub(this._originPointer3D)));
            // Limit the movement, to avoid putting scan on wrong sole or loosing it in the distance
            const offset = 30 * ScanController.SCALE_TO_M_FACTOR;
            const limit = 300 * ScanController.SCALE_TO_M_FACTOR;
            meshPos.y = Math.min(Math.max(meshPos.y, -limit), limit);
            if (scanController.isRightFoot) {
                meshPos.x = Math.min(Math.max(meshPos.x, offset), limit);
            } else {
                meshPos.x = Math.min(Math.max(meshPos.x, -limit), -offset);
            }
            scanController.currentScanMesh.positionOffset.add(meshPos.clone().sub(prevPos));
        } else {
            // Right button down
            // Rotation movement
            scanController.currentScanMesh.mesh.quaternion.copy(this._originRotation);
            const wp = new Vector3();
            scanController.currentScanMesh.mesh.getWorldPosition(wp);

            // Get signed angle between the two vectors by normal plane
            const vA = this._originPointer3D.clone().sub(wp).normalize();
            const vB = pointer3DPos.sub(wp).normalize();
            let angle = Math.acos(vA.dot(vB));
            const cross = vA.cross(vB);
            const normal = new Vector3(0, 0, 1);
            if (normal.dot(cross) < 0) {
                angle = -angle;
            }
            scanController.currentScanMesh.mesh.rotateOnWorldAxis(new Vector3(0, 0, 1), angle);
            scanController.currentScanMesh.angleOffset = this._originalAngleOffset + angle;
        }
    }
}

export default AlignScanTool;
