import SelectTool from './SelectTool';
import TransformTool from './TransformTool';
import ElementMesh from './meshes/ElementMesh';
import Mode from '@/3d-app/commons/Mode';
import eventBus from '@/3d-app/commons/EventBus';
import ScanController from '@/3d-app/scan/ScanController';
import { setSelectedFoot } from '@/3d-vue-api/storeAPI';
import ModeEnum from '@/3d-app/scan/ModeEnum';
import { degToRad, radToDeg } from 'three/src/math/MathUtils';
import type TElementParams from './TElementParams';
import type { Vector2 } from 'three';

/**
 * Mode for managing the elements on the sole.
 * These elements are only visible on this mode, and will stay on the sole.
 * The user can add a new element on the sole. An element can be selected with left click.
 * When selected, no other element can be selected.
 * To go back to selection mode, press escape.
 * The user can move the selected element on the sole by dragging it.
 * The current movement can be cancelled if the user press escape.
 * Current element can be removed by pressing delete or backspace.
 * In this mode, the view can't be rotated.
 */
class ElementMode extends Mode {
    private _selectTool: SelectTool;

    private _transformTool: TransformTool;

    private _placedElements: Array<ElementMesh>;

    private _keyEventListener: EventListener;

    private _pointerDownEventListener: EventListener;

    private _pointerUpEventListener: EventListener;

    private _pointerMoveEventListener: EventListener;

    private _footUpdateEventListener: symbol;

    private _elementOpacity: number;

    set elementOpacity(opacity: number) {
        this._elementOpacity = opacity;
        // Update selected element
        this._selectTool.selectedElement?.setOpacity(this._elementOpacity);
    }

    constructor() {
        super(ModeEnum.ELEMENT);
        this._selectTool = new SelectTool();
        this._transformTool = new TransformTool();
        this._placedElements = [];
        this._keyEventListener = (event: Event) => this.onKeyEvent(event as KeyboardEvent);
        this._pointerDownEventListener = (event: Event) => this.onPointerDownEvent(event as PointerEvent);
        this._pointerUpEventListener = (event: Event) => this.onPointerUpEvent(event as PointerEvent);
        this._pointerMoveEventListener = (event: Event) => this.onPointerMoveEvent(event as PointerEvent);
        this._footUpdateEventListener = Symbol('Foot update event listener');
        this._elementOpacity = 1.0;
    }

    activate(): void {
        const scanController = ScanController.instance;
        if (!scanController) {
            throw new Error('ScanController not ready!');
        }
        window.addEventListener('keydown', this._keyEventListener);
        scanController.canvas?.addEventListener('pointermove', this._pointerMoveEventListener);
        scanController.canvas?.addEventListener('pointerdown', this._pointerDownEventListener);
        scanController.canvas?.addEventListener('pointerup', this._pointerUpEventListener);
        this._footUpdateEventListener = eventBus.on('selected-foot-updated', this.onFootUpdated.bind(this));

        scanController.camera.setOrthographic(true); // Set view to orthographic
        this.updateElementsVisibility(true); // Restore previously hidden elements
        ScanController.focusCurrentFoot();
        scanController.camera.setRotationEnabled(false, true); // Lock camera rotation
        this.selectElement(null);
        scanController.updateHighlighted([]);

        super.activate();
    }

    deactivate(): void {
        const scanController = ScanController.instance;
        if (!scanController) {
            throw new Error('ScanController not ready!');
        }
        window.removeEventListener('keydown', this._keyEventListener);
        scanController.canvas?.removeEventListener('pointermove', this._pointerMoveEventListener);
        scanController.canvas?.removeEventListener('pointerdown', this._pointerDownEventListener);
        scanController.canvas?.removeEventListener('pointerup', this._pointerUpEventListener);
        eventBus.off(this._footUpdateEventListener);

        this.updateElementsVisibility(false); // Hide elements
        // Restore highlight foot
        scanController.updateHighlighted([scanController.currentSoleMesh.mesh]);
        scanController.camera.setRotationEnabled(true, true); // Unlock camera rotation
        scanController.camera.setOrthographic(false); // Set view to perspective

        super.deactivate();
    }

    private updateElementsVisibility(visible: boolean): void {
        this._placedElements.forEach((elt) => {
            elt.visible = visible;
        });
    }

    symmetrizeElement(elementId: number): Promise<number> {
        const element = this.getElementById(elementId);
        if (element) {
            const scanController = ScanController.instance;
            if (!scanController) {
                throw new Error('ScanController not ready!');
            }
            // this.selectElement(null);
            if (element.isParentFootRight === scanController.isRightFoot) {
                // Go to opposite foot
                scanController.isRightFoot = !scanController.isRightFoot;
            }
            // Check if there is not already an element with same parameters
            // Position is rounded to prevents precision issues
            const otherElements = scanController.currentSoleMesh.attached.children;
            for (let i = 0; i < otherElements.length; i++) {
                const roundFactor = 0.1 / ScanController.SCALE_TO_M_FACTOR;
                if (
                    Math.round(otherElements[i].position.x * roundFactor) ===
                        Math.round(-element.position.x * roundFactor) &&
                    Math.round(otherElements[i].position.y * roundFactor) ===
                        Math.round(element.position.y * roundFactor) &&
                    otherElements[i].rotation.z === -element.rotation.z &&
                    otherElements[i].scale.equals(element.scale) &&
                    (otherElements[i] as ElementMesh).boundingBox.equals(element.boundingBox)
                ) {
                    // Revert changes
                    scanController.isRightFoot = !scanController.isRightFoot;
                    return Promise.reject(new Error('Element already symmetrized'));
                }
            }

            // Clone selected, mirror it and switch current foot
            this.selectElement(null);
            const newElement = element.clone();
            newElement.removeFromParent();
            newElement.mirrorMesh();
            newElement.isParentFootRight = !element.isParentFootRight;
            // Attach clone to other sole
            scanController.currentSoleMesh.attached.add(newElement);
            this._placedElements.push(newElement);
            // Mirror position in X
            newElement.position.x *= -1;
            // Invert z rotation
            newElement.rotation.z *= -1;
            // Revert foot change before focus
            scanController.isRightFoot = !scanController.isRightFoot;
            // Select new element
            this.selectElement(newElement);
            // Update element opacity
            newElement.setOpacity(this._elementOpacity);

            return Promise.resolve(newElement.id);
        }
        return Promise.reject(new Error(`Element id ${elementId} does not exist.`));
    }

    private onFootUpdated(): void {
        this.selectElement(null);
        ScanController.focusCurrentFoot();
    }

    private onKeyEvent(event: KeyboardEvent): void {
        if (event.key === 'Escape') {
            if (this._selectTool.selectedElement !== null) {
                if (this._transformTool.isInTransform) {
                    // When transforming, reset to before transformations
                    this._transformTool.cancelTransform();
                } else {
                    // Or just unselect selected element
                    this.selectElement(null);
                }
            }
        } else if (event.key === 'Delete' || event.key === 'Backspace') {
            // Delete selected element
            const selected = this._selectTool.selectedElement;
            if (selected) {
                this.deleteElement(selected);
            }
        }
    }

    /**
     * Add an element on the sole.
     * When done, the id of the added element will be returned in the promise
     * @param element the element to add, a 3dm as ArrayBuffer
     * @param params the element parameters
     * @param isRightSole specify the parent sole (leave empty for current sole)
     * @param canMove set to false to prevents the element to be moved (true by default)
     * @param canRotate set to false to prevents the element to be resized (true by default)
     * @param canScale set to false to prevents the element to be resized (true by default)
     * @returns a promise containing the id of the added element
     */
    public addElement(
        element: ArrayBuffer,
        params: TElementParams,
        isRightSole: boolean | undefined = undefined,
        canMove: boolean = true,
        canRotate: boolean = true,
        canScale: boolean = true,
    ): Promise<number> {
        const scanController = ScanController.instance;
        if (!scanController) {
            throw new Error('ScanController not ready!');
        }
        let isRightParent = scanController.isRightFoot;
        if (isRightSole !== undefined) {
            isRightParent = isRightSole;
        }

        const elementMesh = new ElementMesh(isRightParent, canMove, canRotate, canScale);
        elementMesh.visible = scanController.modeManager.currentMode === this; // Hide element if added outside this mode
        this._placedElements.push(elementMesh);

        const parent = scanController.getSoleMesh(isRightParent);
        const origin = parent.origin.clone().sub(parent.attached.position);

        return new Promise((resolve) =>
            elementMesh.loadMesh(element).then((newElement) => {
                parent.attached.add(newElement);
                newElement.position.x =
                    (params.xPosition + ScanController.XOFFSET) *
                        ScanController.footRatio *
                        ScanController.SCALE_TO_M_FACTOR +
                    origin.x;
                newElement.position.y =
                    origin.y - params.yPosition * ScanController.SCALE_TO_M_FACTOR * ScanController.footRatio;
                newElement.rotation.z = degToRad(params.zRotation);
                newElement.scale.x = params.xScale;
                newElement.scale.y = params.yScale;

                resolve(newElement.id);
            }),
        );
    }

    /**
     * Adapt all elements position and scale to the new foot size
     * @param previousSize the previous foot size (to compute delta)
     */
    public readaptElementsToFootSize(previousSize: number): void {
        const deltaSize = ScanController.footSize / previousSize;

        const scanController = ScanController.instance;
        if (!scanController) {
            throw new Error('ScanController not ready!');
        }

        this._placedElements.forEach((element) => {
            const parent = scanController.getSoleMesh(element.isParentFootRight);
            const origin = parent.origin.clone().sub(parent.attached.position);
            // Offset the elements with sole origin, which is in top left (so +x, -y)
            element.position.x = (element.position.x - origin.x) * deltaSize + origin.x;
            element.position.y = origin.y - (origin.y - element.position.y) * deltaSize;
            if (element.canScale) {
                element.children[0].scale.multiplyScalar(deltaSize);
                element.updateBoundingBox();
            }
        });
    }

    /**
     * Return element infos for export from element id. Will throw error if element is not found.
     * @param elementId unique identifier of the element
     * @param scaled true for scaled values instead of direct ones (to send to Rhino)
     * @returns the element parameters to save
     */
    public getElementInfosById(elementId: number, scaled: boolean = false): TElementParams {
        const element = this.getElementById(elementId);
        if (!element) {
            throw new Error(`Element id ${elementId} does not exist.`);
        }
        const scanController = ScanController.instance;
        if (!scanController) {
            throw new Error('ScanController not ready!');
        }

        const parent = scanController.getSoleMesh(element.isParentFootRight);

        const origin = parent.origin.clone().sub(parent.attached.position);

        const scaleFactor = scaled ? ScanController.footRatio : 1;

        return {
            xPosition:
                ((element.position.x - origin.x) / (ScanController.footRatio * ScanController.SCALE_TO_M_FACTOR) -
                    ScanController.XOFFSET) *
                scaleFactor,
            yPosition:
                ((origin.y - element.position.y) / (ScanController.footRatio * ScanController.SCALE_TO_M_FACTOR)) *
                scaleFactor,
            zRotation: radToDeg(element.rotation.z),
            xScale: element.scale.x * scaleFactor,
            yScale: element.scale.y * scaleFactor,
        };
    }

    /**
     * Delete the element identified by Id. Will throw error if element is not found.
     * @param elementId unique identifier of the element
     */
    public deleteElementById(elementId: number): void {
        const element = this.getElementById(elementId);
        if (element) {
            this.deleteElement(element);
        } else {
            throw new Error(`Element id ${elementId} does not exist.`);
        }
    }

    /**
     * Select the element identified by Id. Will throw error if element is not found.
     * Will change current foot if element is on other foot
     * @param elementId unique identifier of the element (null to deselect)
     */
    public selectElementById(elementId: number | null): void {
        if (elementId) {
            const element = this.getElementById(elementId);
            if (element) {
                this.selectElement(element);
            } else {
                throw new Error(`Element id ${elementId} does not exist.`);
            }
        } else {
            this.selectElement(null);
        }
    }

    public setElementOpacityById(elementId: number, opacity: number) {
        const element = this.getElementById(elementId);
        if (element) {
            element.setOpacity(opacity);
        } else {
            throw new Error(`Element id ${elementId} does not exist.`);
        }
    }

    private selectElement(element: ElementMesh | null): void {
        if (this._selectTool.selectedElement !== element) {
            const scanController = ScanController.instance;
            if (!scanController) {
                throw new Error('ScanController not ready!');
            }
            if (element && element.isParentFootRight !== scanController.isRightFoot) {
                scanController.isRightFoot = !scanController.isRightFoot;
                scanController.updateSelectedFoot();
                setSelectedFoot(scanController.isRightFoot); // Notify change to vue
            }
            this._selectTool.updateSelectedElement(element);
            this._transformTool.updateControls(element);
        }
    }

    private getElementById(id: number): ElementMesh | undefined {
        return this._placedElements.find((elt) => elt.id === id);
    }

    private deleteElement(element: ElementMesh): void {
        // Unselect, remove from array and dispose selected element
        if (this._selectTool.selectedElement === element) {
            // Unselect only if selected
            this.selectElement(null);
        }
        this._placedElements.splice(this._placedElements.indexOf(element), 1);
        element.dispose();
        // Notify the change for when the user deletes the element in 3d view (suppr)
        eventBus.emit('element-deleted', element.id);
    }

    private onPointerDownEvent(event: PointerEvent): void {
        if (event.button === 0 && !event.shiftKey && !event.ctrlKey) {
            // Left button only, no shift nor ctrl (to prevent panning during transform)
            if (!this._selectTool.selectedElement) {
                // Select element mode
                const selectedElement = this._selectTool.pointerDownLogic(event.offsetX, event.offsetY);
                if (selectedElement) {
                    this._transformTool.updateControls(selectedElement);
                    ScanController.instance?.render(); // Force render to update gizmo before raycast
                    this._transformTool.pointerDownLogic(event.offsetX, event.offsetY);
                }
            } else {
                const isPicked = this._transformTool.pointerDownLogic(event.offsetX, event.offsetY);
                if (!isPicked) {
                    // If nothing picked (click on empty space), unselect selected element
                    this.selectElement(null);
                }
            }
            event.preventDefault(); // Prevent selection of UI elements
        }
    }

    private onPointerUpEvent(event: PointerEvent): void {
        if (event.button === 0) {
            if (this._selectTool.selectedElement) {
                this._transformTool.pointerUpLogic();
            }
        }
    }

    private onPointerMoveEvent(event: PointerEvent): void {
        if (this._selectTool.selectedElement === null) {
            this._selectTool.pointerMoveLogic(event.offsetX, event.offsetY);
        } else {
            this._transformTool.pointerMoveLogic(event.offsetX, event.offsetY);
        }
    }

    public changeElementsOrigin(originX: number, originY: number, isRightSole: boolean) {
        this._placedElements.forEach((element) => {
            if (element.isParentFootRight === isRightSole) {
                // Offset is from sole reference point (top left, so +x, -y)
                element.position.x += originX;
                element.position.y = originY - element.position.y;
            }
        });
    }

    /**
     * Update materials that need renderer size (lines)
     * @param newRenderSize new size of the renderer
     */
    public resize(newRenderSize: Vector2): void {
        this._placedElements.forEach((element) => {
            element.resize(newRenderSize);
        });
    }

    public dispose(): void {
        this._placedElements.forEach((element) => {
            element.dispose();
        });
        this._placedElements.length = 0;
        this._transformTool.dispose(); // Remove transform gizmo, too
    }
}

export default ElementMode;
