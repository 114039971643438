import AlignSoleTool from './AlignSoleTool';
import ScanController from '@/3d-app/scan/ScanController';
import Mode from '@/3d-app/commons/Mode';
import ModeEnum from '@/3d-app/scan/ModeEnum';

/**
 * Mode for adjusting the current sole position.
 * The user can only move it along z axis, with left click.
 * The user need to click on the sole to move it.
 * During movement, camera transformation is disabled.
 */
class AlignSoleMode extends Mode {
    private _alignSoleTool: AlignSoleTool;

    private _pointerDownEventListener: EventListener;

    private _pointerUpEventListener: EventListener;

    private _pointerMoveEventListener: EventListener;

    constructor() {
        super(ModeEnum.ALIGN_SOLE);

        this._alignSoleTool = new AlignSoleTool();
        this._pointerDownEventListener = (event: Event) => this.onPointerDownEvent(event as PointerEvent);
        this._pointerUpEventListener = (event: Event) => this.onPointerUpEvent(event as PointerEvent);
        this._pointerMoveEventListener = (event: Event) => this.onPointerMoveEvent(event as PointerEvent);
    }

    activate(): void {
        const scanController = ScanController.instance;
        if (!scanController) {
            throw new Error('ScanController not ready!');
        }
        // Add event listeners
        scanController.canvas?.addEventListener('pointerdown', this._pointerDownEventListener);
        scanController.canvas?.addEventListener('pointerup', this._pointerUpEventListener);
        scanController.canvas?.addEventListener('pointermove', this._pointerMoveEventListener);

        super.activate();
    }

    deactivate(): void {
        const scanController = ScanController.instance;
        if (!scanController) {
            throw new Error('ScanController not ready!');
        }
        // Remove event listeners
        scanController.canvas?.removeEventListener('pointerdown', this._pointerDownEventListener);
        scanController.canvas?.removeEventListener('pointerup', this._pointerUpEventListener);
        scanController.canvas?.removeEventListener('pointermove', this._pointerMoveEventListener);

        // Re-enables camera controls
        scanController.camera.setRotationEnabled(true);
        scanController.camera.setPanEnabled(true);

        super.deactivate();
    }

    private onPointerDownEvent(event: PointerEvent): void {
        if (event.button === 0) {
            // Left pointer only
            this._alignSoleTool.pointerDownLogic(event.offsetX, event.offsetY);
            if (this._alignSoleTool.isInTransform) {
                // Disable camera controls during transformation
                ScanController.instance?.camera.setRotationEnabled(false);
                ScanController.instance?.camera.setPanEnabled(false);
                event.preventDefault();
            }
        }
    }

    private onPointerUpEvent(event: PointerEvent): void {
        if (event.button === 0) {
            // Left pointer only
            this._alignSoleTool.pointerUpLogic();
            // Re-enables camera controls
            ScanController.instance?.camera.setRotationEnabled(true);
            ScanController.instance?.camera.setPanEnabled(true);
        }
    }

    private onPointerMoveEvent(event: PointerEvent): void {
        this._alignSoleTool.pointerMoveLogic(event.offsetX, event.offsetY);
    }
}

export default AlignSoleMode;
