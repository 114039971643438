import AlignScanTool from './AlignScanTool';
import ScanController from '@/3d-app/scan/ScanController';
import Mode from '@/3d-app/commons/Mode';
import ModeEnum from '@/3d-app/scan/ModeEnum';

/**
 * Mode for adjusting the current scan on the current sole.
 * The user can move the scan with left click, and rotate the scan around it's pivot point
 * with left click.
 * While in this mode, the camera can't be rotated or panned.
 */
class AlignScanMode extends Mode {
    private _alignScanTool: AlignScanTool;

    private _pointerDownEventListener: EventListener;

    private _pointerUpEventListener: EventListener;

    private _pointerMoveEventListener: EventListener;

    private _contextMenuListener: EventListener;

    constructor() {
        super(ModeEnum.ALIGN_SCAN);

        this._alignScanTool = new AlignScanTool();
        this._pointerDownEventListener = (event: Event) => this.onPointerDownEvent(event as PointerEvent);
        this._pointerUpEventListener = (event: Event) => this.onPointerUpEvent(event as PointerEvent);
        this._pointerMoveEventListener = (event: Event) => this.onPointerMoveEvent(event as PointerEvent);
        this._contextMenuListener = (event: Event) => event.preventDefault();
    }

    activate(): void {
        const scanController = ScanController.instance;
        if (!scanController) {
            throw new Error('ScanController not ready!');
        }
        // Add event listeners
        scanController.canvas?.addEventListener('pointerdown', this._pointerDownEventListener);
        scanController.canvas?.addEventListener('pointerup', this._pointerUpEventListener);
        scanController.canvas?.addEventListener('pointermove', this._pointerMoveEventListener);
        scanController.canvas?.addEventListener('contextmenu', this._contextMenuListener);

        super.activate();
    }

    deactivate(): void {
        const scanController = ScanController.instance;
        if (!scanController) {
            throw new Error('ScanController not ready!');
        }
        // Remove event listeners
        scanController.canvas?.removeEventListener('pointerdown', this._pointerDownEventListener);
        scanController.canvas?.removeEventListener('pointerup', this._pointerUpEventListener);
        scanController.canvas?.removeEventListener('pointermove', this._pointerMoveEventListener);
        scanController.canvas?.removeEventListener('contextmenu', this._contextMenuListener);

        // Re-enables camera controls
        scanController.camera.setRotationEnabled(true);
        scanController.camera.setPanEnabled(true);

        super.deactivate();
    }

    private onPointerDownEvent(event: PointerEvent): void {
        this._alignScanTool.pointerDownLogic(event.button, event.offsetX, event.offsetY);
        if (this._alignScanTool.isInTransform) {
            // Disable camera controls during transformation
            ScanController.instance?.camera.setRotationEnabled(false);
            ScanController.instance?.camera.setPanEnabled(false);
            event.preventDefault();
        }
    }

    private onPointerUpEvent(event: PointerEvent): void {
        this._alignScanTool.pointerUpLogic(event.button);
        // Re-enables camera controls
        if (!this._alignScanTool.isInTransform) {
            ScanController.instance?.camera.setRotationEnabled(true);
            ScanController.instance?.camera.setPanEnabled(true);
        }
    }

    private onPointerMoveEvent(event: PointerEvent): void {
        this._alignScanTool.pointerMoveLogic(event.offsetX, event.offsetY);
    }
}

export default AlignScanMode;
