import type { Vector3 } from 'three';

export enum ControlsOperation {
    PAN = 'PAN',
    ROTATE = 'ROTATE',
    ZOOM = 'ZOOM',
}

export type TControlMouse = 0 | 1 | 2 | 'WHEEL';

export enum TouchGesture {
    ONE_PAN = 'pan',
    TWO_PAN = 'twofingerpan',
    PINCH = 'pinch',
    ROTATE = 'rotate',
}

export enum ControlKey {
    SHIFT = 'SHIFT',
    CTRL = 'CTRL',
}

export type TTouchActions = {
    pan: ControlsOperation | null;
    twofingerpan: ControlsOperation | null;
    pinch: ControlsOperation | null;
    rotate: ControlsOperation | null;
};

export interface ICameraControls {
    update(): unknown;
    touchActions: TTouchActions;
    enableRotate: boolean;
    enablePan: boolean;
    enableZoom: boolean;
    enableDamping: boolean;
    dampingFactor: number;
    target: Vector3;
    addEventListener(arg0: string, arg1: () => void): unknown;
    rotateSpeed: number;
    zoomSpeed: number;
    panSpeed: number;
    maxDistance: number;
    minDistance: number;
    addMouseAction(operation: ControlsOperation, mouse: TControlMouse, key?: ControlKey): void;
    removeMouseAction(mouse: TControlMouse, key?: ControlKey): boolean;
    addTouchAction(operation: ControlsOperation, touchGesture: TouchGesture): void;
    removeTouchAction(touchGesture: TouchGesture): boolean;
    dispose(): void;
}
