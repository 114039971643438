import ElementMesh from './meshes/ElementMesh';
import ScanController from '@/3d-app/scan/ScanController';
import Tool from '@/3d-app/commons/Tool';
import eventBus from '@/3d-app/commons/EventBus';
import { Raycaster, Vector2 } from 'three';

class SelectTool extends Tool {
    private _selectedElement: ElementMesh | null;

    private _raycaster: Raycaster;

    private _highlightedElement: ElementMesh | null;

    get selectedElement(): ElementMesh | null {
        return this._selectedElement;
    }

    public constructor() {
        super();
        this._selectedElement = null;
        this._highlightedElement = null;
        this._raycaster = new Raycaster();
    }

    public updateSelectedElement(newElement: ElementMesh | null): void {
        if (this._selectedElement) {
            this._selectedElement.children[0].renderOrder = 100;
        }
        this._highlightedElement?.setHighlighted(false);
        this._highlightedElement = null;
        this._selectedElement = newElement;
        if (this._selectedElement) {
            this._selectedElement.children[0].renderOrder = 101; // Put selected on foreground
        }

        // Notify the change for the view
        eventBus.emit('selected-element-updated', this._selectedElement?.id);
    }

    public pointerDownLogic(pointerX: number, pointerY: number): ElementMesh | null {
        const element = this.getElementUnderPointer(pointerX, pointerY);
        this.updateSelectedElement(element);
        return element;
    }

    public pointerMoveLogic(pointerX: number, pointerY: number): void {
        // Highlight element under pointer
        const elt = this.getElementUnderPointer(pointerX, pointerY);
        if (this._highlightedElement === elt) {
            return;
        }
        if (this._highlightedElement) {
            // Put previously highlighted on background, and change material
            this._highlightedElement.setHighlighted(false);
            this._highlightedElement.children[0].renderOrder = 100;
        }
        this._highlightedElement = elt;
        if (this._highlightedElement) {
            // Put currently highlighted on foreground, and change material
            this._highlightedElement.setHighlighted(true);
            this._highlightedElement.children[0].renderOrder = 101;
        }
    }

    /**
     * Return the element that is under the pointer
     * @param posX pointer X offset in canvas
     * @param posY pointer Y offset in canvas
     * @returns The element under the pointer, or null
     */
    private getElementUnderPointer(posX: number, posY: number): ElementMesh | null {
        const scanController = ScanController.instance;
        if (!scanController) {
            throw new Error('ScanController not ready!');
        }
        this._raycaster.setFromCamera(
            new Vector2(
                (posX / scanController.containerSize.width) * 2 - 1,
                -(posY / scanController.containerSize.height) * 2 + 1,
            ),
            scanController.camera,
        );

        const hit = this._raycaster.intersectObjects(scanController.currentSoleMesh.attached.children);
        if (hit.length > 0) {
            let hitObj = hit[0].object;
            // Get top parent
            while (!(hitObj instanceof ElementMesh) && hitObj.parent) {
                hitObj = hitObj.parent;
            }

            return hitObj as ElementMesh;
        }

        return null;
    }
}

export default SelectTool;
