import ScanController from '@/3d-app/scan/ScanController';
import MeshUtils from '@/3d-app/commons/MeshUtils';
import {
    Box3,
    Group,
    DoubleSide,
    Matrix4,
    Material,
    Mesh,
    LoadingManager,
    MeshBasicMaterial,
    PlaneGeometry,
    TextureLoader,
    ObjectLoader,
    Vector3,
} from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader';
import type ScanFilesBlobInterface from '@/domains/scan/typescript/interfaces/ScanFilesBlobInterface';

class ScanMesh {
    private _scanObject: Group;

    private _scanGeometry: Group;

    private _pivot: Group;

    private _attached: Group;

    private _materials: Array<Material>;

    private _isVisible: boolean;

    positionOffset: Vector3;

    angleOffset: number;

    /**
     * Get the whole scan, with attached childs
     */
    get mesh(): Group {
        return this._scanObject;
    }

    /**
     * Get only attached childs, without the scan geometry
     */
    get attached(): Group {
        return this._attached;
    }

    /**
     * Get only the scan geometry, without the attached childs
     */
    get scanGeometry(): Group {
        return this._scanGeometry;
    }

    /**
     * Get the scan pivot, containing attached childs and scan geometry, with foot offset
     */
    get pivot(): Group {
        return this._pivot;
    }

    constructor() {
        this._scanGeometry = new Group();
        this._attached = new Group();
        this._attached.name = 'attached';
        this._pivot = new Group();
        this._pivot.name = 'pivot';
        this._scanObject = new Group();
        this._scanObject.name = 'scanObject';
        this._materials = [];
        this._isVisible = true;
        this.positionOffset = new Vector3(0, 0, 0);
        this.angleOffset = 0;
    }

    /**
     * Load the scan mesh
     * @param isRightSole true to load right scan, false to load left scan
     * @param scan the object containing all blobs (obj, mtl and jpg)
     * @returns promise resolved when object is loaded, with the loaded group
     */
    loadScan(isRightScan: boolean, scan: ScanFilesBlobInterface): Promise<Group> {
        const mtlFile = URL.createObjectURL(scan.mtl);
        const objFile = URL.createObjectURL(scan.obj);
        const jpgFile = URL.createObjectURL(scan.jpg);

        const loadingManager = new LoadingManager();
        loadingManager.setURLModifier((url) => {
            if (url === mtlFile) {
                return url;
            }

            return jpgFile;
        });

        // Load the 3D scan
        const mtlLoader = new MTLLoader(loadingManager);
        mtlLoader.setMaterialOptions({ side: DoubleSide }); // Material on both side

        return new Promise((resolve) => {
            mtlLoader.load(mtlFile, (materials) => {
                materials.preload();
                const objLoader = new OBJLoader();
                objLoader.setMaterials(materials);

                objLoader.load(objFile, (objectGroup) => {
                    objectGroup.scale.setScalar(ScanController.SCALE_TO_M_FACTOR); // Scale down object

                    const bbox = new Box3().setFromObject(objectGroup); // Get bounding box
                    const posDiff = bbox.min.add(bbox.max).divideScalar(-2); // Find object center with bounding box info

                    // Update object center
                    objectGroup.applyMatrix4(new Matrix4().makeTranslation(posDiff.x, posDiff.y, posDiff.z));

                    // Update object orientation
                    objectGroup.applyMatrix4(new Matrix4().makeRotationZ(-Math.PI / 2));

                    this._scanGeometry = objectGroup;
                    this._scanGeometry.name = 'scanGeometry';

                    // Add the object to a group for future pivot point transforms
                    this._pivot.add(this._scanGeometry);

                    this._pivot.add(this._attached);

                    this._scanObject.add(this._pivot);

                    // Keep reference to the materials and add transparency to them
                    this._scanObject.traverse((scanObject) => {
                        if (scanObject instanceof Mesh) {
                            this._materials.push(scanObject.material);
                        }
                    });
                    this._materials.forEach((material) => {
                        material.transparent = true;
                        material.alphaTest = 0.05;
                    });

                    // Offset the position for right or left
                    this._scanObject.position.x += ScanController.getFootOffset(isRightScan);

                    // Offset by sole thickness and minimal bounding box
                    const isSoleVisible = ScanController.instance?.currentSoleMesh.getIsVisible();
                    this._scanObject.position.z +=
                        (isSoleVisible
                            ? ScanController.getSoleThickness(isRightScan) * ScanController.SCALE_TO_M_FACTOR * 10
                            : 0) +
                        (bbox.min.z - bbox.max.z) / 2;

                    this.applyOffsets(); // Reapply the saved offsets

                    // Free references
                    URL.revokeObjectURL(mtlFile);
                    URL.revokeObjectURL(objFile);
                    URL.revokeObjectURL(jpgFile);

                    ScanController.instance?.updateSelectedFoot();

                    resolve(this._scanObject);
                });
            });
        });
    }

    /**
     * Load the 2d scan and create the mesh
     * @param isRightSole true to load right scan, false to load left scan
     * @param image the blobs containing the jpg image of the 2d scan
     * @param dpi the image resolution
     * @returns promise resolved when object is loaded, with the loaded group
     */
    load2dScan(isRightScan: boolean, image: Blob, dpi: number): Promise<Group> {
        const jpgFile = URL.createObjectURL(image);

        return new Promise((resolve) => {
            // Load texture
            new TextureLoader().load(jpgFile, (texture) => {
                const scanMat = new MeshBasicMaterial({
                    side: DoubleSide,
                    transparent: true,
                    alphaTest: 0.05,
                    map: texture,
                });
                this._materials.push(scanMat);

                const scaleFactor = (ScanController.SCALE_TO_M_FACTOR * 25.4) / dpi;

                const width = texture.image.naturalWidth * scaleFactor;
                const height = texture.image.naturalHeight * scaleFactor;
                const mesh = new Mesh(new PlaneGeometry(width, height), scanMat);
                this._scanGeometry = new Group();
                this._scanGeometry.name = 'scanGeometry';
                this._scanGeometry.add(mesh);

                // Add the object to a group for future pivot point transforms
                this._pivot.add(this._scanGeometry);

                this._pivot.add(this._attached);

                this._scanObject.add(this._pivot);

                // Offset the position for right or left
                this._scanObject.position.x += ScanController.getFootOffset(isRightScan);

                // Offset by sole thickness
                const isSoleVisible = ScanController.instance?.currentSoleMesh.getIsVisible();
                this._scanObject.position.z += isSoleVisible
                    ? ScanController.getSoleThickness(isRightScan) * ScanController.SCALE_TO_M_FACTOR * 10
                    : 0;

                this.applyOffsets(); // Reapply the saved offsets

                // Free references
                URL.revokeObjectURL(jpgFile);

                ScanController.instance?.updateSelectedFoot();

                resolve(this._scanObject);
            });
        });
    }

    /**
     * Reset scan offset in position and rotation
     */
    recenter(): void {
        this.revertOffsets();
        this.positionOffset = new Vector3(0, 0, 0);
        this.angleOffset = 0;
    }

    private revertOffsets(): void {
        this._scanObject.position.sub(this.positionOffset);
        this._scanObject.rotateOnWorldAxis(new Vector3(0, 0, 1), -this.angleOffset);
    }

    /**
     * Apply the current offsets in position and rotation
     */
    applyOffsets(): void {
        this._scanObject.position.add(this.positionOffset);
        this._scanObject.rotateOnWorldAxis(new Vector3(0, 0, 1), this.angleOffset);
    }

    setOpacity(opacity: number): void {
        this._materials.forEach((material) => {
            material.opacity = opacity;
        });
        this.updateVisibility();
    }

    getOpacity(): number {
        if (this._materials.length > 0) {
            return this._materials[0].opacity;
        }
        return 1;
    }

    setIsVisible(isVisible: boolean): void {
        this._isVisible = isVisible;
        this.updateVisibility();
    }

    getIsVisible(): boolean {
        return this._isVisible;
    }

    updateVisibility(): void {
        let isCurrentOrVisible = true;
        if (ScanController.instance) {
            isCurrentOrVisible =
                ScanController.instance.isOtherFootVisible || ScanController.instance.currentScanMesh === this;
        }
        this._scanGeometry.visible = this._isVisible && isCurrentOrVisible && this.getOpacity() > 0;
        this._attached.visible = this._scanGeometry.visible;
    }

    toJSON(): any {
        // Save current opacity
        const savedVisibility = this._scanGeometry.visible;
        let savedOpacity = 1;
        if (this._materials.length > 0) {
            savedOpacity = this._materials[0].opacity;
        }
        this.setOpacity(1); // Save at full opacity (we could also save current opacity)
        this._scanGeometry.visible = true;
        this.revertOffsets(); // Save at initial position and rotation
        const json = this._scanObject.toJSON();
        this.applyOffsets(); // Restore saved offsets
        this._scanGeometry.visible = savedVisibility;
        this.setOpacity(savedOpacity); // Restore opacity
        return json;
    }

    fromJSON(json: any, isRightScan: boolean): Promise<Group | undefined> {
        this.dispose();
        const loader = new ObjectLoader();

        return new Promise((resolve) => {
            loader.parse(json, (obj) => {
                this._scanObject = obj as Group;

                if (this._scanObject.children.length > 0) {
                    // If there is a scan
                    this._pivot = this._scanObject.children.find((child) => child.name === 'pivot') as Group;

                    this._attached = this._pivot.children.find((child) => child.name === 'attached') as Group;
                    if (!this._attached) {
                        this._attached = new Group();
                        this._attached.name = 'attached';
                    }
                    ScanController.instance?.modeManager.alignPointMode.loadPoints(
                        this._attached.children as Array<Mesh>,
                        isRightScan,
                    );
                    this._scanGeometry = this._pivot.children.find((child) => child.name === 'scanGeometry') as Group;
                    if (!this._scanGeometry) {
                        this._scanGeometry = this._pivot.children[0] as Group;
                        this._scanGeometry.name = 'scanGeometry';
                    }
                    this._scanGeometry.traverse((scanObject) => {
                        if (scanObject instanceof Mesh) {
                            this._materials.push(scanObject.material);
                        }
                    });

                    this.updateVisibility();

                    resolve(this._scanObject);
                } else {
                    resolve(undefined);
                }
            });
        });
    }

    dispose(reset = true): void {
        this._materials.forEach((material) => (material as MeshBasicMaterial).map?.dispose()); // Dispose all textures
        MeshUtils.disposeObject(this._scanObject);

        if (reset) {
            // Reset modifications (transforms)
            this._pivot = new Group();
            this._pivot.name = 'pivot';
        }
        this._scanObject = new Group();
        this._scanObject.name = 'scanObject';
        this._attached.name = 'attached';
        this._materials.length = 0;
    }
}

export default ScanMesh;
