import {
    ControlsOperation,
    type TControlMouse,
    ControlKey,
    type ICameraControls,
    TouchGesture,
    type TTouchActions,
} from '@/3d-app/commons/camera/controls/ICameraControls';
import { GestureEvent, Pan, Pinch, PointerListener, Rotate, TwoFingerPan } from 'contactjs';
import {
    EventDispatcher,
    PerspectiveCamera,
    Quaternion,
    Vector2,
    Vector3,
    type Camera,
    MathUtils,
    Matrix4,
} from 'three';

// events
const _changeEvent = { type: 'change' };
const _startEvent = { type: 'start' };
const _endEvent = { type: 'end' };

class CustomTrackballControls extends EventDispatcher implements ICameraControls {
    // Public properties
    public domElement: HTMLElement;
    public camera: Camera;
    public enabled: boolean = true;
    public mouseActions: { operation: ControlsOperation; mouse: TControlMouse; key?: ControlKey | undefined }[] = [];
    public touchActions: TTouchActions = {
        pan: null,
        twofingerpan: null,
        rotate: null,
        pinch: null,
    };

    public rotateSpeed: number = 0.5;
    public zoomSpeed: number = 2;
    public panSpeed: number = 0.002;
    public enableZoom: boolean = true;
    public enableRotate: boolean = true;
    public enablePan: boolean = true;
    public target: Vector3 = new Vector3();
    public maxDistance: number = Infinity;
    public minDistance: number = 0;
    public enableDamping: boolean = false;
    public dampingFactor: number = 0.95;

    // Private properties
    private _prevTime: number = 0;
    private _rotAxis: Vector3 = new Vector3();
    private _rotAmount: number = 0;
    private _prevPos: Vector3 = new Vector3();
    private _mouseStartPosition: Vector2 = new Vector2();
    private _mouseStartSpherePosition: Vector3 = new Vector3();
    private _targetStartPosition: Vector3 = new Vector3();
    private _cameraStartPosition: Vector3 = new Vector3();
    private _cameraStartRotation: Quaternion = new Quaternion();
    private _currentOperation: ControlsOperation = ControlsOperation.ROTATE;
    private _isInMouseOperation: boolean = false;

    private _pointerListener: PointerListener;

    constructor(camera: Camera, domElement: HTMLElement) {
        super();
        this.domElement = domElement;
        this.domElement.style.touchAction = 'none'; // Disable touch events propagation
        this.camera = camera;

        this.domElement.addEventListener('contextmenu', this.#onContextMenuCallback);
        this.domElement.addEventListener('wheel', this.#onWheelCallback);
        this.domElement.addEventListener('mousedown', this.#onMouseDownCallback);

        this._pointerListener = new PointerListener(domElement, {
            supportedGestures: [Pan, Pinch, Rotate, TwoFingerPan],
        });

        this._pointerListener.on('panstart', this.#onPanStartCallback);
        this._pointerListener.on('twofingerpanstart', this.#onDoublePanStartCallback);
        this._pointerListener.on('rotatestart', this.#onRotateStartCallback);
        this._pointerListener.on('pinchstart', this.#onPinchStartCallback);
    }

    update(): unknown {
        if (this.enabled && this.enableDamping && this._rotAmount > 0 && this.enableRotate) {
            const finalRot = new Quaternion().setFromAxisAngle(this._rotAxis, this._rotAmount);
            // Update the camera's position by applying the rotation around the target
            const offset = this.camera.position.clone().sub(this.target); // Get the offset from the target
            offset.applyQuaternion(finalRot); // Rotate the offset
            this.camera.position.copy(this.target).add(offset); // Set the new position

            // Update the camera's quaternion to include the rotation
            this.camera.quaternion.multiplyQuaternions(finalRot, this.camera.quaternion);
            this.dispatchEvent(_changeEvent as never);

            this._rotAmount *= this.dampingFactor;
            if (this._rotAmount < 0.001) {
                this._rotAmount = 0;
                this._isInMouseOperation = false;
                this.dispatchEvent(_endEvent as never);
            }
        }
        return this.enabled;
    }

    private static mapScreenToSphere(screenPosition: Vector2) {
        const windowSize = new Vector2(window.innerWidth, window.innerHeight).multiplyScalar(window.devicePixelRatio);

        const longSide = Math.max(windowSize.x, windowSize.y);
        const normMouse = new Vector2(
            (screenPosition.x - windowSize.x / 2) / (longSide / 2),
            -(screenPosition.y - windowSize.y / 2) / (longSide / 2),
        );

        const quadrance = normMouse.lengthSq();
        if (quadrance > 1) {
            // The point is outside the sphere, we place it to the border at z = 0
            const norm = 1 / Math.sqrt(quadrance);
            return new Vector3(normMouse.x * norm, normMouse.y * norm, 0);
        }
        return new Vector3(normMouse.x, normMouse.y, Math.sqrt(1 - quadrance));
    }

    /**
     * Computes the vector needed to zoom the camera in or out towards a target position.
     * The method respects minimum and maximum zoom distances to prevent the camera from
     * getting too close or too far from the target.
     *
     * @param zoomDelta - The change in zoom level (from mouse scroll).
     * @param cameraPos - The current camera position
     * @param targetPos - The target position to zoom towards
     * @returns A Vector3 representing the movement needed to achieve the desired zoom.
     */
    private computeZoomVector(
        zoomDelta: number,
        cameraPos: Vector3 = this.camera.position,
        targetPos: Vector3 = this.target,
    ): Vector3 {
        const notchDeltaY = 12500; // The zoom effect of one mouse wheel notch (arbitrary, adjust it to your needs)

        // Calculate zoom variation based on the zoom speed, camera field of view, and scroll distance.
        const zoomVariation =
            -this.zoomSpeed * (zoomDelta / notchDeltaY) * (80 / (this.camera as PerspectiveCamera).fov);

        // Compute the direction vector from the camera to the target and scale it by the zoom variation.
        const direction = targetPos.clone().sub(cameraPos).normalize();
        const zoomVector = direction.clone().multiplyScalar(zoomVariation);

        // Calculate the maximum and minimum positions the camera can reach based on zoom constraints.
        const maxZoomLimit = targetPos.clone().sub(direction.clone().multiplyScalar(this.maxDistance)).sub(cameraPos);
        const minZoomLimit = targetPos.clone().sub(direction.clone().multiplyScalar(this.minDistance)).sub(cameraPos);

        // Determine the appropriate zoom vector based on zoom direction and distance limits.
        if (zoomVariation > 0 && zoomVector.lengthSq() > minZoomLimit.lengthSq()) {
            return minZoomLimit;
        }
        if (zoomVariation < 0 && zoomVector.lengthSq() > maxZoomLimit.lengthSq()) {
            return maxZoomLimit;
        }
        return zoomVector;
    }

    /**
     * Remove all listeners, stop animations and clean scene
     */
    dispose(): void {
        this.domElement.removeEventListener('mousedown', this.#onMouseDownCallback);
        this.domElement.removeEventListener('wheel', this.#onWheelCallback);
        this.domElement.removeEventListener('contextmenu', this.#onContextMenuCallback);

        window.removeEventListener('mousemove', this.#onMouseMoveCallback);
        window.removeEventListener('mouseup', this.#onMouseUpCallback);
        this._pointerListener.destroy();
    }

    // #region MOUSE

    // #region MOUSE LOGIC

    /**
     * Set a new mouse action by specifying the operation to be performed and a mouse/key combination.
     * In case of conflict, replaces the existing one.
     * @param {ControlsOperation} operation The operation to be performed (PAN, ROTATE, ZOOM)
     * @param {TControlMouse} mouse A mouse button (0, 1, 2) or 'WHEEL' for wheel notches
     * @param {ControlKey | undefined} key The keyboard modifier ('CTRL', 'SHIFT') or null if key is not needed
     */
    addMouseAction(operation: ControlsOperation, mouse: TControlMouse, key?: ControlKey | undefined): void {
        let state;
        if (mouse === 'WHEEL') {
            if (operation !== ControlsOperation.ZOOM) {
                // cannot associate 2D operation to 1D input
                return;
            }
        }
        switch (operation) {
            case 'PAN':
                state = ControlsOperation.PAN;
                break;
            case 'ROTATE':
                state = ControlsOperation.ROTATE;
                break;
            default:
                state = ControlsOperation.ZOOM;
                break;
        }
        const action = {
            operation,
            mouse,
            key,
            state,
        };
        for (let i = 0; i < this.mouseActions.length; i++) {
            if (this.mouseActions[i].mouse === action.mouse && this.mouseActions[i].key === action.key) {
                this.mouseActions.splice(i, 1, action);
                return;
            }
        }
        this.mouseActions.push(action);
    }

    /**
     * Remove a mouse action by specifying its mouse/key combination
     * @param {*} mouse A mouse button (0, 1, 2) or 'WHEEL' for wheel notches
     * @param {*} key The keyboard modifier ('CTRL', 'SHIFT') or null if key is not needed
     * @returns {Boolean} True if the operation has been succesfully removed, false otherwise
     */
    removeMouseAction(mouse: TControlMouse, key?: ControlKey | undefined): boolean {
        for (let i = 0; i < this.mouseActions.length; i++) {
            if (this.mouseActions[i].mouse === mouse && this.mouseActions[i].key === key) {
                this.mouseActions.splice(i, 1);
                return true;
            }
        }
        return false;
    }

    /**
     * Return the operation associated to a mouse/keyboard combination
     * @param {TControlMouse} mouseBtn A mouse button (0, 1, 2) or 'WHEEL' for wheel notches
     * @param {ControlKey | undefined} keyBtn The keyboard modifier ('CTRL', 'SHIFT') or undefined if key is not needed
     * @returns The operation if it has been found, null otherwise
     */
    getMouseOpFromAction(mouseBtn: TControlMouse, keyBtn?: ControlKey): ControlsOperation | null {
        for (let i = 0; i < this.mouseActions.length; i++) {
            const { mouse, key, operation } = this.mouseActions[i];
            // eslint-disable-next-line eqeqeq
            if (mouse === mouseBtn && key == keyBtn) {
                return operation;
            }
        }
        if (!keyBtn) {
            return null;
        }

        for (let i = 0; i < this.mouseActions.length; i++) {
            const { mouse, key, operation } = this.mouseActions[i];
            if (mouse === mouseBtn && key == null) {
                return operation;
            }
        }

        return null;
    }

    operationStart(event: MouseEvent) {
        if (!this.enabled) {
            return;
        }

        if (this._rotAmount > 0) {
            this._rotAmount = 0;
            this.dispatchEvent(_endEvent as never);
        }
        this._isInMouseOperation = true;
        this.dispatchEvent(_startEvent as never);
        this._mouseStartPosition = new Vector2(event.clientX, event.clientY).multiplyScalar(window.devicePixelRatio);
        this._targetStartPosition = this.target.clone();
        this._cameraStartPosition = this.camera.position.clone();
        if (this._currentOperation === ControlsOperation.ROTATE) {
            this._mouseStartSpherePosition = CustomTrackballControls.mapScreenToSphere(
                this._mouseStartPosition,
            ).applyQuaternion(this.camera.quaternion);
            this._cameraStartRotation = this.camera.quaternion.clone();
        }
    }

    operationMove(event: MouseEvent) {
        if (!this.enabled) {
            return;
        }

        const mouseCurrentPosition = new Vector2(event.clientX, event.clientY).multiplyScalar(window.devicePixelRatio);
        switch (this._currentOperation) {
            case ControlsOperation.PAN:
                if (this.enablePan) {
                    // Pan amount is scaled with distance to target
                    const distanceToTarget = this.camera.position.distanceTo(this.target);
                    const posDiff = mouseCurrentPosition
                        .sub(this._mouseStartPosition)
                        .multiplyScalar(
                            (this.panSpeed / 80) * distanceToTarget * (this.camera as PerspectiveCamera).fov,
                        );
                    const convDiff = new Vector3(-posDiff.x, posDiff.y, 0).applyQuaternion(this.camera.quaternion);
                    this.target.copy(convDiff.clone().add(this._targetStartPosition));
                    this.camera.position.copy(convDiff.add(this._cameraStartPosition));
                }
                break;
            case ControlsOperation.ROTATE:
                if (this.enableRotate) {
                    const endPos = CustomTrackballControls.mapScreenToSphere(mouseCurrentPosition).applyQuaternion(
                        this._cameraStartRotation,
                    );

                    // Compute the vector perpendicular to the begin and end vectors
                    const rotAxis = new Vector3().crossVectors(this._mouseStartSpherePosition, endPos);
                    let rotQuat;
                    if (rotAxis.lengthSq() > 0.000001) {
                        rotQuat = new Quaternion().setFromAxisAngle(
                            rotAxis.normalize(),
                            this._mouseStartSpherePosition.angleTo(endPos) * this.rotateSpeed * 5,
                        );
                    } else {
                        rotQuat = new Quaternion(); // Identity quaternion (no rotation)
                    }
                    rotQuat.invert();

                    if (this.enableDamping) {
                        this._prevTime = performance.now();
                        this._prevPos = this.camera.position.clone().sub(this.target);
                    }

                    const targetToCamera = this._cameraStartPosition
                        .clone()
                        .sub(this._targetStartPosition)
                        .applyQuaternion(rotQuat);
                    this.camera.position.copy(targetToCamera.add(this.target));
                    this.camera.quaternion.copy(rotQuat.multiply(this._cameraStartRotation));
                }
                break;
            default:
                if (this.enableZoom) {
                    const zoomVector = this.computeZoomVector(
                        mouseCurrentPosition.y - this._mouseStartPosition.y,
                        this._cameraStartPosition,
                        this._targetStartPosition,
                    );
                    this.camera.position.copy(zoomVector.add(this._cameraStartPosition));
                }
                break;
        }
        this.dispatchEvent(_changeEvent as never);
    }

    operationEnd() {
        if (!this._isInMouseOperation) {
            return;
        }

        if (this.enableDamping && this._currentOperation === ControlsOperation.ROTATE && this.enableRotate) {
            const timeSinceRelease = performance.now() - this._prevTime;
            if (timeSinceRelease < 1) {
                const currentPos = this.camera.position.clone().sub(this.target);
                this._rotAxis.crossVectors(this._prevPos, currentPos).normalize();
                this._rotAmount = this._prevPos.angleTo(currentPos);
                return;
            }
        }

        this._isInMouseOperation = false;
        this.dispatchEvent(_endEvent as never);
    }

    // #endregion MOUSE LOGIC

    // #region MOUSE EVENTS

    #onContextMenuCallback = (event: MouseEvent) => {
        if (!this.enabled) {
            return;
        }

        for (let i = 0; i < this.mouseActions.length; i++) {
            if (this.mouseActions[i].mouse === 2) {
                // prevent only if button 2 is actually used
                event.preventDefault();
                break;
            }
        }
    };

    #onMouseDownCallback = (event: MouseEvent) => {
        let modifier;

        if (event.ctrlKey || event.metaKey) {
            modifier = ControlKey.CTRL;
        } else if (event.shiftKey) {
            modifier = ControlKey.SHIFT;
        }

        const mouseOp = this.getMouseOpFromAction(event.button as TControlMouse, modifier);
        if (mouseOp) {
            this._currentOperation = mouseOp;
            window.addEventListener('mousemove', this.#onMouseMoveCallback);
            window.addEventListener('mouseup', this.#onMouseUpCallback);
            this.operationStart(event);
        }
    };

    #onMouseUpCallback = () => {
        window.removeEventListener('mousemove', this.#onMouseMoveCallback);
        window.removeEventListener('mouseup', this.#onMouseUpCallback);
        this.operationEnd();
    };

    #onMouseMoveCallback = (event: MouseEvent) => {
        this.operationMove(event);
    };

    #onWheelCallback = (event: WheelEvent) => {
        if (this.enabled && this.enableZoom) {
            let modifier;

            if (event.ctrlKey || event.metaKey) {
                modifier = ControlKey.CTRL;
            } else if (event.shiftKey) {
                modifier = ControlKey.SHIFT;
            }

            const mouseOp = this.getMouseOpFromAction('WHEEL', modifier);

            if (mouseOp != null) {
                event.preventDefault();
                this.dispatchEvent(_startEvent as never);
                if (this._isInMouseOperation) {
                    // Adapt initial camera position while in another operation
                    this._cameraStartPosition.add(
                        this.computeZoomVector(event.deltaY, this._cameraStartPosition, this._targetStartPosition),
                    );
                }
                this.camera.position.add(this.computeZoomVector(event.deltaY));

                this.dispatchEvent(_changeEvent as never);
                this.dispatchEvent(_endEvent as never);
            }
        }
    };

    // #endregion MOUSE EVENTS

    // #endregion MOUSE

    // #region TOUCH

    // #region TOUCH LOGIC

    /**
     * Set a new touch action by specifying the operation to be performed.
     * In case of conflict, replaces the existing one.
     * @param {ControlsOperation} operation The operation to be performed (PAN, ROTATE, ZOOM)
     * @param {TouchGesture} touchGesture The type of touch. See enum TouchGesture for the list available.
     */
    addTouchAction(operation: ControlsOperation, touchGesture: TouchGesture): void {
        this.touchActions[touchGesture] = operation;
    }

    /**
     * Remove a touch action by specifying its touch type
     * @param {TouchGesture} touchGesture The touchGesture to remove from actions
     * @returns {Boolean} True if the action has been succesfully removed, false otherwise
     */
    removeTouchAction(touchGesture: TouchGesture): boolean {
        if (this.touchActions[touchGesture]) {
            this.touchActions[touchGesture] = null;
            return true;
        }
        return false;
    }

    touchOperationStart() {
        if (!this.enabled) {
            return;
        }

        if (this._rotAmount > 0) {
            this._rotAmount = 0;
        }
        this.dispatchEvent(_startEvent as never);
    }

    touchOperationMove(event: GestureEvent) {
        if (!this.enabled) {
            return;
        }

        const currentOperation = this.touchActions[event.type as TouchGesture];

        if (currentOperation === ControlsOperation.PAN) {
            if (this.enablePan) {
                let panDiff;
                if (event.type === 'rotate') {
                    panDiff = new Vector2(1, 0).multiplyScalar(event.detail.live.rotation * 3);
                } else if (event.type === 'pinch') {
                    panDiff = new Vector2(1, 0).multiplyScalar((event.detail.live.scale - 1) * 100);
                } else {
                    panDiff = new Vector2(event.detail.live.deltaX, event.detail.live.deltaY);
                    if (event.type === 'pan') {
                        panDiff.multiplyScalar(2);
                    }
                }

                // Pan amount is scaled with distance to target
                const distanceToTarget = this.camera.position.distanceTo(this.target);
                const posDiff = panDiff.multiplyScalar(
                    window.devicePixelRatio *
                        (this.panSpeed / 5300) *
                        distanceToTarget *
                        (this.camera as PerspectiveCamera).fov,
                );
                const convDiff = new Vector3(-posDiff.x, posDiff.y, 0).applyQuaternion(this.camera.quaternion);
                this.target.add(convDiff);
                this.camera.position.add(convDiff);
            }
        } else if (currentOperation === ControlsOperation.ROTATE) {
            if (this.enableRotate) {
                if (this.enableDamping) {
                    this._prevPos = this.camera.position.clone().sub(this.target);
                }
                if (event.type === 'rotate') {
                    this.camera.rotateOnAxis(new Vector3(0, 0, 1), MathUtils.degToRad(event.detail.live.rotation) / 16);
                } else if (event.type === 'pinch') {
                    const invTr = this.target.clone().multiplyScalar(-1);
                    this.camera.applyMatrix4(
                        new Matrix4()
                            .makeTranslation(this.target.x, this.target.y, this.target.z)
                            .multiply(new Matrix4().makeRotationY((event.detail.live.scale - 1) * 0.03))
                            .multiply(new Matrix4().makeTranslation(invTr.x, invTr.y, invTr.z)),
                    );
                } else {
                    const centerVec = new Vector2(
                        event.detail.live.center.x,
                        event.detail.live.center.y,
                    ).multiplyScalar(window.devicePixelRatio);
                    const endPos = CustomTrackballControls.mapScreenToSphere(centerVec).applyQuaternion(
                        this.camera.quaternion,
                    );
                    let deltaFactor = window.devicePixelRatio * 0.2;
                    if (event.type === 'pan') {
                        deltaFactor *= 2;
                    }
                    const startPos = CustomTrackballControls.mapScreenToSphere(
                        centerVec.sub(
                            new Vector2(event.detail.live.deltaX, event.detail.live.deltaY).multiplyScalar(deltaFactor),
                        ),
                    ).applyQuaternion(this.camera.quaternion);

                    // Compute the vector perpendicular to the begin and end vectors
                    const rotAxis = new Vector3().crossVectors(endPos, startPos);
                    if (rotAxis.lengthSq() > 0.000001) {
                        const invTr = this.target.clone().multiplyScalar(-1);
                        this.camera.applyMatrix4(
                            new Matrix4()
                                .makeTranslation(this.target.x, this.target.y, this.target.z)
                                .multiply(
                                    new Matrix4().makeRotationAxis(
                                        rotAxis.normalize(),
                                        startPos.angleTo(endPos) * this.rotateSpeed,
                                    ),
                                )
                                .multiply(new Matrix4().makeTranslation(invTr.x, invTr.y, invTr.z)),
                        );
                    }
                }
            }
        } else if (this.enableZoom) {
            let zoomDelta = 0;
            if (event.type === 'rotate') {
                zoomDelta = -event.detail.live.rotation;
            } else if (event.type === 'pinch') {
                zoomDelta = (event.detail.live.scale - 1) * -50;
            } else {
                zoomDelta = event.detail.live.deltaY / 5;
                if (event.type === 'pan') {
                    zoomDelta *= 2;
                }
            }

            const zoomVector = this.computeZoomVector(
                zoomDelta * window.devicePixelRatio,
                this.camera.position,
                this.target,
            );
            this.camera.position.add(zoomVector);
        }
        this.dispatchEvent(_changeEvent as never);
    }

    touchOperationEnd(event: GestureEvent) {
        if (this.enableDamping && this.enableRotate) {
            const currentPos = this.camera.position.clone().sub(this.target);
            if (
                event.type !== 'rotateend' &&
                this.touchActions[event.type.slice(0, -3) as TouchGesture] === ControlsOperation.ROTATE
            ) {
                if (event.detail.live.speed > 50 && currentPos !== this._prevPos) {
                    this._rotAxis.crossVectors(this._prevPos, currentPos).normalize();
                    this._rotAmount = this._prevPos.angleTo(currentPos);
                    return;
                }
            } else {
                this._prevPos = this.camera.position.clone().sub(this.target);
            }
        }
        this.dispatchEvent(_endEvent as never);
    }

    // #endregion TOUCH LOGIC

    // #region TOUCH EVENTS

    #onPanStartCallback = (event: Event) => {
        if ((event as GestureEvent).detail.live.srcEvent.pointerType === 'mouse') {
            return;
        }
        if (this.touchActions.pan) {
            this._pointerListener.on('pan', this.#onPanCallback);
            this._pointerListener.on('panend', this.#onPanEndCallback);
            this.touchOperationStart();
        }
    };

    #onPanCallback = (event: Event) => {
        if ((event as GestureEvent).detail.live.srcEvent.pointerType === 'mouse') {
            return;
        }
        this.touchOperationMove(event as GestureEvent);
    };

    #onPanEndCallback = (event: Event) => {
        if ((event as GestureEvent).detail.live.srcEvent.pointerType === 'mouse') {
            return;
        }
        this._pointerListener.off('pan', this.#onPanCallback);
        this._pointerListener.off('panend', this.#onPanEndCallback);
        this.touchOperationEnd(event as GestureEvent);
    };

    #onDoublePanStartCallback = () => {
        if (this.touchActions.twofingerpan) {
            this._pointerListener.on('twofingerpan', this.#onDoublePanCallback);
            this._pointerListener.on('twofingerpanend', this.#onDoublePanEndCallback);
            this.touchOperationStart();
        }
    };

    #onDoublePanCallback = (event: Event) => {
        this.touchOperationMove(event as GestureEvent);
    };

    #onDoublePanEndCallback = (event: Event) => {
        this._pointerListener.off('twofingerpan', this.#onPanCallback);
        this._pointerListener.off('twofingerpanend', this.#onPanEndCallback);
        this.touchOperationEnd(event as GestureEvent);
    };

    #onRotateStartCallback = () => {
        if (this.touchActions.rotate) {
            this._pointerListener.on('rotate', this.#onRotateCallback);
            this._pointerListener.on('rotateend', this.#onRotateEndCallback);
            this.touchOperationStart();
        }
    };

    #onRotateCallback = (event: Event) => {
        this.touchOperationMove(event as GestureEvent);
    };

    #onRotateEndCallback = (event: Event) => {
        this._pointerListener.off('rotate', this.#onPanCallback);
        this._pointerListener.off('rotateend', this.#onPanEndCallback);
        this.touchOperationEnd(event as GestureEvent);
    };

    #onPinchStartCallback = () => {
        if (this.touchActions.pinch) {
            this._pointerListener.on('pinch', this.#onPinchCallback);
            this._pointerListener.on('pinchend', this.#onPinchEndCallback);
            this.touchOperationStart();
        }
    };

    #onPinchCallback = (event: Event) => {
        this.touchOperationMove(event as GestureEvent);
    };

    #onPinchEndCallback = (event: Event) => {
        this._pointerListener.off('pinch', this.#onPanCallback);
        this._pointerListener.off('pinchend', this.#onPanEndCallback);
        this.touchOperationEnd(event as GestureEvent);
    };

    // #endregion TOUCH EVENTS

    // #endregion TOUCH
}

export default CustomTrackballControls;
