import FootCoordinate from './FootCoordinates';
import AlignPointFactory from './AlignPointFactory';
import ScanController from '@/3d-app/scan/ScanController';
import MeshUtils from '@/3d-app/commons/MeshUtils';
import Tool from '@/3d-app/commons/Tool';
import eventBus from '@/3d-app/commons/EventBus';
import { Raycaster, type Mesh, Vector3, Vector2 } from 'three';

const CURSOR_INFOS: { color: string; label: string }[] = [
    { color: 'lime', label: 'C' },
    { color: 'red', label: 'I' },
    { color: 'yellow', label: 'V' },
    { color: 'blue', label: 'A' },
];

class AlignPointTool extends Tool {
    private _leftAlignPoints: Array<Mesh> = [];

    private _rightAlignPoints: Array<Mesh> = [];

    private _raycaster: THREE.Raycaster;

    /**
     * Return current foot points
     */
    get points(): Array<Mesh> {
        if (ScanController.instance?.isRightFoot) {
            return this._rightAlignPoints;
        }

        return this._leftAlignPoints;
    }

    loadPoints(points: Array<Mesh>, isRightPoints: boolean) {
        if (isRightPoints) {
            this._rightAlignPoints.forEach((point) => {
                MeshUtils.disposeObject(point);
            });
            this._rightAlignPoints.length = 0;
            this._rightAlignPoints = Object.assign([], points);
        } else {
            this._leftAlignPoints.forEach((point) => {
                MeshUtils.disposeObject(point);
            });
            this._leftAlignPoints.length = 0;
            this._leftAlignPoints = Object.assign([], points);
        }
    }

    public constructor() {
        super();

        this._raycaster = new Raycaster();
    }

    /**
     * Return scan points of specified scan
     * @param isRightScan true for right points, false for left
     * @returns the points meshes
     */
    public getScanPoints(isRightScan: boolean): Array<Mesh> {
        return isRightScan ? this._rightAlignPoints : this._leftAlignPoints;
    }

    public mouseClickLogic(mouseX: number, mouseY: number): void {
        if (this.points.length > 3) {
            this.dispose(false); // Remove previous align points
        }

        this.placeAlignPoint(mouseX, mouseY);

        if (this.points.length > 3) {
            if (ScanController.instance) {
                ScanController.instance.currentScanMesh.recenter(); // Reset custom transformations
                this.computeTransformations(ScanController.instance.isRightFoot);
                // Exit place align points mode, but keep points
                ScanController.instance.modeManager.deactivateCurrentMode();
                eventBus.emit('align-point-tool:points-completed');
            }
        }
    }

    /**
     * Toggle points visibility on or off
     * @param isVisible true for visible points, false for invisible
     */
    public setPointsVisibility(isVisible: boolean): void {
        this._leftAlignPoints.forEach((point) => {
            point.visible = isVisible;
        });
        this._rightAlignPoints.forEach((point) => {
            point.visible = isVisible;
        });
    }

    /**
     * Compute transformations (orient plane to plane with parameters)
     */
    public computeTransformations(isRightScan: boolean): void {
        const scanController = ScanController.instance;
        if (!scanController) {
            throw new Error('ScanController not ready!');
        }

        const currentScan = scanController.getScanMesh(isRightScan);
        const scanMesh = currentScan.mesh;
        const isSoleVisible = scanController.getSoleMesh(isRightScan).getIsVisible();
        const soleThickness = isSoleVisible ? ScanController.getSoleThickness(isRightScan) : 0;
        const heelDepth = isSoleVisible ? ScanController.getHeelDepth(isRightScan) : 0;

        const pointMeshes = this.getScanPoints(isRightScan);
        if (pointMeshes.length < 3) {
            // Compute simple transformation
            const offsetSize = 4; // in mm, up until 3 mm you can have part of sole mesh which interact with images/3d scan
            const offsetThickness = isSoleVisible ? offsetSize : 0;
            const newPosition = (soleThickness + heelDepth + offsetThickness) * ScanController.SCALE_TO_M_FACTOR;
            scanMesh.position.z = newPosition;
            return;
        }

        const { pivot } = currentScan;
        pivot.position.set(0, 0, 0);
        scanMesh.rotation.set(0, 0, 0); // Reset mesh rotation
        scanMesh.position.set(0, 0, 0); // Reset mesh position

        const points = pointMeshes.map((pt) => pt.position.clone());

        const heel = FootCoordinate.heel(isRightScan);
        const meta1 = FootCoordinate.meta1(isRightScan);
        const meta5 = FootCoordinate.meta5(isRightScan);

        const { origin } = scanController.getTemplateMesh(isRightScan);

        const targetPoints = [
            new Vector3(
                heel.x + origin.x,
                origin.y - heel.y,
                origin.z +
                    soleThickness * ScanController.SCALE_TO_M_FACTOR +
                    heelDepth * ScanController.SCALE_TO_M_FACTOR,
            ),
            new Vector3(
                meta1.x + origin.x,
                origin.y - meta1.y,
                origin.z + soleThickness * ScanController.SCALE_TO_M_FACTOR,
            ),
            new Vector3(
                meta5.x + origin.x,
                origin.y - meta5.y,
                origin.z + soleThickness * ScanController.SCALE_TO_M_FACTOR,
            ),
        ];

        // Step 1: Match first points positions and define it as pivot point
        pivot.position.sub(points[0]);
        const added = targetPoints[0].clone().sub(points[0]);
        scanMesh.position.add(points[0]).add(added);
        points.forEach((pt) => pt.add(added)); // Update points positions too

        // Step 2: Align second point to second target point
        const planeNormal = points[1].clone().sub(points[0]).normalize();
        scanMesh.quaternion.setFromUnitVectors(planeNormal, targetPoints[1].clone().sub(targetPoints[0]).normalize());

        // Update points from world positions
        for (let i = 0; i < 3; i++) {
            pointMeshes[i].getWorldPosition(points[i]);
        }

        // Step 3: Align third point to third target point by rotating on projected plane
        scanMesh.rotateOnAxis(
            planeNormal,
            (isRightScan ? -1 : 1) *
                points[2]
                    .clone()
                    .sub(points[0])
                    .projectOnPlane(planeNormal)
                    .angleTo(targetPoints[2].clone().sub(targetPoints[0]).projectOnPlane(planeNormal)),
        );

        // Offset the position for right or left
        scanMesh.position.x += ScanController.getFootOffset(isRightScan);
        currentScan.applyOffsets(); // Reapply the saved offsets
    }

    /**
     * Place align point on given position
     * @param x X position of the point to add
     * @param y Y position of the point to add
     */
    private placeAlignPoint(x: number, y: number): void {
        const scanController = ScanController.instance;
        if (!scanController) {
            throw new Error('ScanController not ready!');
        }
        const newPos = this.getPositionUnderMouse(x, y);
        if (newPos) {
            const newPoint = AlignPointFactory.getPoint(this.points.length);
            newPoint.position.copy(newPos);
            scanController.currentScanMesh.attached.attach(newPoint);
            this.points.push(newPoint);
            if (this.points.length < 4) {
                this.updateCustomCursor();
            }
        }
    }

    private getPositionUnderMouse(posX: number, posY: number): THREE.Vector3 | null {
        const scanController = ScanController.instance;
        if (!scanController) {
            throw new Error('ScanController not ready!');
        }
        this._raycaster.setFromCamera(
            new Vector2(
                (posX / scanController.containerSize.width) * 2 - 1,
                -(posY / scanController.containerSize.height) * 2 + 1,
            ),
            scanController.camera,
        );

        const hit = this._raycaster.intersectObject(scanController.currentScanMesh.mesh);
        if (hit.length > 0) {
            return hit[0].point;
        }

        return null;
    }

    static addCustomCursor() {
        const wrapper = document.querySelector('#webgl-wrapper') as HTMLElement;
        if (wrapper) {
            wrapper.style.cursor = 'none';
            const customCursor = document.createElement('div');
            customCursor.id = 'custom-cursor';
            customCursor.innerText = 'C';
            customCursor.style.background = 'lime';
            customCursor.style.textAlign = 'center';
            customCursor.style.color = 'white';
            customCursor.style.fontWeight = 'bolder';
            customCursor.style.position = 'absolute';
            customCursor.style.width = '20px';
            customCursor.style.height = '20px';
            customCursor.style.zIndex = '1000';
            customCursor.style.pointerEvents = 'none';
            customCursor.style.borderRadius = '50%';
            customCursor.style.marginLeft = '-10px';
            customCursor.style.marginTop = '-10px';
            customCursor.hidden = true;
            wrapper.insertBefore(customCursor, wrapper.firstChild);
            wrapper.addEventListener('mousemove', (e) => {
                const targetPosition = wrapper.getBoundingClientRect();
                const relativeX = e.x - targetPosition.left;
                const relativeY = e.y - targetPosition.top;
                customCursor.style.transform = `translate3d(${relativeX}px, ${relativeY}px, 0)`;
            });
            wrapper.addEventListener('mouseenter', () => {
                customCursor.hidden = false;
            });
            wrapper.addEventListener('mouseleave', () => {
                customCursor.hidden = true;
            });
        }
    }

    static removeCustomCursor() {
        const wrapper = document.querySelector('#webgl-wrapper') as HTMLElement;
        if (wrapper) {
            wrapper.style.cursor = 'auto';
            document.body.querySelector('#custom-cursor')?.remove();
        }
    }

    updateCustomCursor() {
        const customCursor = document.body.querySelector('#custom-cursor') as HTMLElement;
        if (customCursor) {
            const pointIndex = this.points.length % 4;
            customCursor.innerText = CURSOR_INFOS[pointIndex].label;
            customCursor.style.background = CURSOR_INFOS[pointIndex].color;
        }
    }

    dispose(all: boolean = true): void {
        if (all) {
            this._leftAlignPoints.forEach((point) => {
                MeshUtils.disposeObject(point);
            });
            this._rightAlignPoints.forEach((point) => {
                MeshUtils.disposeObject(point);
            });
            this._leftAlignPoints.length = 0;
            this._rightAlignPoints.length = 0;
        } else {
            this.points.forEach((point) => {
                MeshUtils.disposeObject(point);
            });
            this.points.length = 0;
        }
    }
}

export default AlignPointTool;
