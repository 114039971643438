import ScanController from './ScanController';
import { ControlKey, ControlsOperation } from '@/3d-app/commons/camera/controls/ICameraControls';
import NavigationController from '@/3d-app/navigation/NavigationController';
import TrackballCamera from '@/3d-app/commons/camera/TrackballCamera';
import World from '@/3d-app/commons/world/World';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import {
    AmbientLight,
    Color,
    DirectionalLight,
    NormalBlending,
    Object3D,
    Vector2,
    WebGLRenderTarget,
    type ColorRepresentation,
} from 'three';
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';
import { GammaCorrectionShader } from 'three/examples/jsm/shaders/GammaCorrectionShader';
import type AnimatedCamera from '@/3d-app/commons/camera/AnimatedCamera';
import type TWorldParams from '@/3d-app/commons/world/TWorldParams';

class ScanWorld extends World {
    public static readonly Y_OFFSET_CAMERA = -0.0005; // Camera view offset to compensate UI format
    public static readonly HOME_CAMERA_DISTANCE = 6; // Home camera distance factor

    private _composer: EffectComposer;

    private _renderTarget: WebGLRenderTarget;

    private _outlinePass: OutlinePass;

    get camera(): AnimatedCamera {
        return this._camera as AnimatedCamera;
    }

    protected constructor(params: TWorldParams) {
        super(params);

        this.scene.background = new Color(1, 1, 1);

        // Setup camera
        const camera = new TrackballCamera({ rendererDomElement: this._renderer.domElement });
        camera.controls.minDistance = 20 * ScanController.SCALE_TO_M_FACTOR;
        camera.controls.maxDistance = 1000 * ScanController.SCALE_TO_M_FACTOR;
        camera.controls.zoomSpeed = 2;
        camera.controls.enableDamping = true;
        camera.controls.dampingFactor = 0.85;
        camera.position.set(
            0,
            0,
            ScanController.footSize * ScanController.SCALE_TO_M_FACTOR * ScanWorld.HOME_CAMERA_DISTANCE,
        );
        camera.controls.target.set(0, 0, 0);
        NavigationController.setLinkedCamera(camera);
        if ('ontouchstart' in window || navigator.maxTouchPoints > 0) {
            // Mobile specific camera parameters
            camera.controls.rotateSpeed = 0.8;
        } else {
            // Desktop specific camera parameters
            camera.controls.rotateSpeed = 0.4;
        }

        this._camera = camera;
        this._mainLoop.addCallback(this.camera.render.bind(this.camera));
        camera.controls.update();

        // Change mouse behavior
        camera.controls.removeMouseAction(0);
        camera.controls.removeMouseAction(0, ControlKey.CTRL);
        camera.controls.removeMouseAction(0, ControlKey.SHIFT);
        camera.controls.addMouseAction(ControlsOperation.PAN, 1);
        camera.controls.addMouseAction(ControlsOperation.ROTATE, 2);
        camera.controls.addMouseAction(ControlsOperation.ROTATE, 1, ControlKey.SHIFT);
        camera.controls.addMouseAction(ControlsOperation.ROTATE, 1, ControlKey.CTRL);
        camera.controls.addMouseAction(ControlsOperation.ZOOM, 2, ControlKey.CTRL);

        // Lights
        const ambientLight = new AmbientLight(0x888888, 0.3 * Math.PI);

        const cameraLight = new DirectionalLight(0x888888, 2.5 * Math.PI);
        cameraLight.position.set(0, 0, 5);
        this._camera.add(cameraLight);
        this.scene.add(this.camera);
        this.scene.add(ambientLight);

        // Create outline pass (to higlight elements)
        this._renderTarget = new WebGLRenderTarget(this.containerSize.width, this.containerSize.height, {
            samples: Math.min(4, this._renderer.capabilities.maxSamples),
        }); // Add antialiasing
        this._composer = new EffectComposer(this.renderer, this._renderTarget);
        this._outlinePass = new OutlinePass(new Vector2(0, 0), this.scene, this.camera);
        this._outlinePass.overlayMaterial.blending = NormalBlending;
        this._outlinePass.edgeStrength = 7;
        this._outlinePass.edgeThickness = 5;
        // this._outlinePass.pulsePeriod = 4; // Uncomment this for pulsation
        this._outlinePass.visibleEdgeColor = new Color('#9ac00a');
        this._outlinePass.hiddenEdgeColor = new Color('#9ac00a');
        this._composer.addPass(new RenderPass(this.scene, this.camera)); // Add render pass
        this._composer.addPass(this._outlinePass); // Then outline pass
        // Correct the colors
        const gammaCorrectionPass = new ShaderPass(GammaCorrectionShader);
        this._composer.addPass(gammaCorrectionPass);

        // Change camera parameters
        this.camera.near = 0.01;
        // console.log(this._renderer.info); // Uncomment this when in need of memory leak analysis
    }

    updateHighlighted(highlightedObjects: Object3D[], highlightColor: ColorRepresentation = '#9ac00a'): void {
        this._outlinePass.selectedObjects = highlightedObjects;
        this._outlinePass.visibleEdgeColor = new Color(highlightColor);
    }

    resize(): void {
        super.resize();
        // Redefine pass resolution (min 1x1 to prevent GL_INVALID_FRAMEBUFFER_OPERATION)
        this._renderTarget.setSize(Math.max(1, this.containerSize.width), Math.max(1, this.containerSize.height));
    }

    render(): void {
        this._composer.render(); // Update compose pass
    }
}

export default ScanWorld;
